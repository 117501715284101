import Image from "./ImgComponent";
import Link from "next/link";
import React, { useState } from "react";
import { motion } from "framer-motion";
import { Envelope, X } from "react-bootstrap-icons";
import { useRouter } from "next/router";
import langContent from "../i18n/home";
import HtmlRender from "./HtmlRender";
import classNames from "classnames";

const StickyContact = ({ noSticky, directClick, className }) => {
  const [visible, setVisible] = useState(false);
  const { locale } = useRouter();
  const variants = {
    open: {
      right: 0,
      top: "40%",
      opacity: 1,
    },
    closed: {
      right: "-100%",
      top: "40%",
      opacity: 0,
    },
  };
  const toggleAnimate = () => {
    setVisible((prev) => !prev);
    // controls.start({
    //   right: 0,
    //   opacity: 1
    // })
  };
  return (
    // <Link href="/contact">
    // </Link>
    <>
      {!visible && (
        <div
          onClick={directClick ? directClick : toggleAnimate}
          className={classNames(
            "contact-sticky text-center",
            noSticky && "no-sticky",
            className
          )}
        >
          <Image
            width={35}
            height={26}
            src={
              typeof window !== `undefined`
                ? window.location.origin + "/static/speech-bubble.png"
                : "/static/speech-bubble.png"
            }
            alt="contact icon"
          />
          <p className="text-dark-blue mb-0 mt-1">
            {langContent.sticky_contact.title[locale]}
          </p>
        </div>
      )}
      <motion.div
        className="contact_full"
        initial={false}
        animate={visible ? "open" : "closed"}
        variants={variants}
        transition={{
          type: "linear",
        }}
        // whileTap={toggleAnimate}
      >
        <div onClick={toggleAnimate} className="close-btn">
          <X style={{ fontSize: "24px" }} />
        </div>
        <div className="d-flex">
          <div className="left-contact d-flex flex-column justify-content-center">
            <p>
              <HtmlRender>
                {langContent.sticky_contact.left_panel.title[locale]}
              </HtmlRender>
            </p>
            <p className="mb-0">
              <a
                href={`tel: ${process.env.NEXT_PUBLIC_HOTLINE}`}
                className="cursor-pointer"
              >
                {langContent.sticky_contact.left_panel.phone[locale]}
              </a>
            </p>
          </div>
          <div className="right-contact d-flex flex-column justify-content-center">
            <p className="mb-0">
              <HtmlRender>
                {langContent.sticky_contact.right_panel.title[locale]}
              </HtmlRender>
              <Link href="/contact">
                <u>{langContent.sticky_contact.right_panel.here[locale]}</u>
              </Link>
            </p>
            {/* <p><small>
              <Link href="/contact">
                {langContent.sticky_contact.right_panel.email[locale]}
              </Link>
            </small></p>
            <div className="text-center">
              <Envelope style={{fontSize: "24px"}}/>
            </div> */}
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default StickyContact;
